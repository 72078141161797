import React from 'react'
import {Routes,Route} from 'react-router-dom'
import Login from './Login'
import Buy from './Buy'
import Add from './Add'
import {Sell } from './Sell'
import { Download } from './Download'
import AdminJeet from './AdminJeet'
import GenBill from './GenBill'
import { PurcheseAdmin } from './PurcheseAdmin'
import { BillDownload } from './BillDownload'
import PurchaseAcknowledgement from './PurchaseAcknowledgement'
import RequestAc from './RequestAc'
import Wait from './Wait'
import Purchesebill from './Purchesebill'
import Purchesereq from './Purchesereq'






const Routing = () => {

  return (
    <div>
      <Routes>
        <Route path='/' element={<Login/>} />
        <Route path='/sell' element={<Sell/>} />
        <Route path='/purchase' element={<Buy/>} />
        <Route path='/Add' element={<Add/>} />
        <Route path='/bill' element={<AdminJeet/>} />
        <Route path='/download' element={<Download/>} />
        <Route path='/Accept' element={<GenBill/>} />
        <Route path='/Purchese' element={<PurcheseAdmin/>} />
        <Route path='/downbill/:id' element={<BillDownload/>} />
        <Route path='/ekc/:id' element={<PurchaseAcknowledgement/>} />
        <Route path='/req' element={<RequestAc/>} />
        <Route path='/wait' element={<Wait/>} />
        <Route path='/pbill' element={<Purchesebill/>} />
        <Route path='/preq' element={<Purchesereq/>} />
      </Routes>
      
    </div>
  )
}

export default Routing
