import React, { useEffect, useState } from 'react'
import { MDBBtn, MDBIcon, MDBInput } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';
import {  getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, update,push,child,get} from "firebase/database";
import {app} from "./api.js";
import {useNavigate} from 'react-router-dom'

const db = getDatabase(app);
const dbRef = ref(getDatabase(app));
const auth = getAuth(app);


const Home = () => {

  const [admin, setadmin] = useState(false);


useEffect(() => {
  

  onAuthStateChanged(auth, user =>{
    if(user){
      const em =  user.email

      // get(child(dbRef, `auth/`+user.uid)).then((snapshot) => {
      //   if (snapshot.exists()) {
      //     const check = snapshot.val()
      //     if (check.status==="false") {
      //       navi('/wait')
      //     } else {
            
      //     }
      //   } else {
      //     console.log("No data available");
      //     navi('/')
      //   }
      // }).catch((error) => {
      //   console.error(error);
      //   navi('/')
      // });

      get(child(dbRef, `admin/`)).then((snapshot) => {
        if (snapshot.exists()) {
          const radharani = snapshot.val()
          if (radharani.email===em) {
            setadmin(true)
          }
        } else {
          console.log("No data available");
        }
      }).catch((error) => {
        console.error(error);
      });


    }else{

    }
  });

}, [])


        return (
            <div style={{
                textAlign:'center',
                marginTop:80
            }}>
                    <img style={{
                  width:200
              }} src='mylogo.png' alt='Mountreach'/>
        
        
        <div style={{marginTop:30}}>



      {admin?<>
    <Link to="/add" ><MDBBtn style={{marginRight:10}} color='secondary' tag='a' floating>
        <MDBIcon fas icon='magic' />
      </MDBBtn></Link>
        <Link to="/Accept" ><MDBBtn rounded color='primary' style={{width:'110px',marginRight:10}} >Panel</MDBBtn></Link>

        <Link to="/sell" ><MDBBtn rounded color='success' style={{width:'110px'}} >Sell</MDBBtn></Link>
        
        <Link to="/purchase" ><MDBBtn style={{width:'110px'}}rounded className='mx-2' color='danger'>
                  Purchase
                </MDBBtn></Link>
    
    </>
     :<>


<Link to="/sell" ><MDBBtn rounded color='success' style={{width:'180px'}} >Sell</MDBBtn></Link>
<br></br>
                <br></br>
                <Link to="/download" ><MDBBtn rounded color='success' style={{width:'180px'}} >Sell Receipt</MDBBtn></Link>


                <br></br>
                <br></br>

                <Link to="/purchase" ><MDBBtn style={{width:'180px'}}rounded className='mx-2' color='danger'>
                  Purchase
                </MDBBtn></Link>
     <br></br>
                <br></br>
        <Link to="/pbill" ><MDBBtn style={{width:'180px'}}rounded className='mx-2' color='danger'>
                  Purchase Receipt
                </MDBBtn></Link>
     
       </>}
        
    
        
            </div>
        
        
        
            </div>
          )
    }
  

export default Home
