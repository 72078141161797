import React from 'react'
import { MDBBtn } from 'mdb-react-ui-kit';
import {useNavigate,useParams} from 'react-router-dom'
const Wait = () => {
  const navi = useNavigate();
  return (
    <div>
      waiting for Approved email..
      <MDBBtn color='info' onClick={()=>navi('/')}>
        reaload
      </MDBBtn>
    </div>
  )
}

export default Wait
