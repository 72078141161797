import React, { useEffect, useState } from 'react'
import { getDatabase, ref,child,get} from "firebase/database";
import {app} from "./api.js";
import { MDBBtn } from 'mdb-react-ui-kit';
import {useNavigate} from 'react-router-dom'
import {  getAuth, onAuthStateChanged } from "firebase/auth";
import moment from 'moment';
import { MyNav } from './MyNav.js';
import * as XLSX from 'xlsx';
import {MDBIcon } from 'mdb-react-ui-kit';
import html2pdf from 'html2pdf.js';


const auth = getAuth(app);
const db = getDatabase(app);
const dbRef = ref(getDatabase(app));

const AdminJeet = () => {

    const [viewbill, setviewbill] = useState(false);
    const [data, setdata] = useState([])

    const [bill, setbill] = useState([]);
    const [fromdate, setfromdate] = useState("");
    const [Todate, setTodate] = useState("");

    const color =(index)=>{
        if ((index+1)% 2===0) {
          return "#D5FFFC"
        } else {
          return "white"
        }
      }

    const startDate = moment(fromdate).format('yyyy-MM-DD');
    const endDate = moment(Todate).format('yyyy-MM-DD');
  
    const filteredData = data.filter(item => {
      const formattedDate = moment(item.date).format('yyyy-MM-DD');
      const itemDate = new Date(formattedDate);
      const start = new Date(startDate);
      const end = new Date(endDate);
  
      return itemDate >= start && itemDate <= end;
    });


    const navi = useNavigate();
    useEffect(() => {
      onAuthStateChanged(auth, user =>{
        if(user){

          // get(child(dbRef, `auth/`+user.uid)).then((snapshot) => {
          //   if (snapshot.exists()) {
          //     const check = snapshot.val()
          //     if (check.status==="false") {
          //       navi('/wait')
          //     } else {
                
          //     }
          //   } else {
          //     console.log("No data available");
          //     navi('/')
          //   }
          // }).catch((error) => {
          //   console.error(error);
          //   navi('/')
          // });

          const em =  user.email
          
          get(child(dbRef, `admin/`)).then((snapshot) => {
            if (snapshot.exists()) {
              const radharani = snapshot.val()
              if (radharani.email===em) {
               
              }else{
              navi('/')

              }
            } else {
              navi('/')
            }
          }).catch((error) => {
            navi('/')
          });
        }else{
          navi('/')
        }
      });
    }, [])

    const newdata=filteredData.map(item=>({
      BillNumber:item.BillNumber,
      Date:moment(item.Date).format('yyyy-MM-DD'),
      Name:item.Name,
      GST:item.GST,
      product:item.product[0].product,
      Agent:item.Agent,
      bags:item.product[0].bags,
      rate:item.product[0].rate,
      weight:item.product[0].weight,
      weighttype:item.product[0].weighttype,
      average:item.product[0].average,
      amount:item.product[0].amount,
      MandiTax:item.MandiTax,
    //   Commison:i.Commison,
      OtherTax:item.OtherTax,
      Total:item.Total,

    }))

    const getdata=()=>{
        get(child(dbRef, `bills/`)).then((snapshot) => {
            if (snapshot.exists()) {

                const fdata = Object.values(snapshot.val())
                const pending = fdata.filter((item)=> item.Status==='Accepted')
              console.log(Object.values(snapshot.val()));
              setdata(pending)
              console.log(newdata)

            } else {
              console.log("No data available");
            }
          }).catch((error) => {
            console.error(error);
          });
    }

    useEffect(() => {
      getdata()


    }, [])

    
      const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(newdata);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Employees');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const filename = 'EmployeesData.xlsx';
        saveAsExcelFile(excelBuffer, filename);
      };
    
      const saveAsExcelFile = (buffer, filename) => {
        const data = new Blob([buffer], { type: 'application/octet-stream' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(data);
        link.download = filename;
        link.click();
      };

      const convertToPdf = () => {
        const element = document.getElementById('element-to-convert'); // Replace 'element-to-convert' with the ID of the HTML element you want to convert
    
        if (!element) {
          console.error('Element not found');
          return;
        }
    
        html2pdf()
          .from(element)
          .save();
      };




      const convertToWords = (num) => {
        const units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
        const teens = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
        const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
    
        if (num === 0) return 'Zero';
    
        let words = '';
    
        if (num >= 10000000 && num <= 999999999) {
          words += convertToWords(Math.floor(num / 10000000)) + ' Crore ';
          num %= 10000000;
        }
    
        if (num >= 100000 && num <= 9999999) {
          words += convertToWords(Math.floor(num / 100000)) + ' Lakh ';
          num %= 100000;
        }
    
        if (num >= 1000 && num <= 99999) {
          words += convertToWords(Math.floor(num / 1000)) + ' Thousand ';
          num %= 1000;
        }
    
        if (num >= 100 && num <= 999) {
          words += units[Math.floor(num / 100)] + ' Hundred ';
          num %= 100;
        }
    
        if (num >= 20 && num <= 99) {
          words += tens[Math.floor(num / 10)] + ' ';
          num %= 10;
        }
    
        if (num >= 10 && num <= 19) {
          words += teens[num - 10];
          num = 0;
        }
    
        if (num >= 1 && num <= 9) {
          words += units[num];
        }
    
        return words.trim();
      };

      if(viewbill){
        return(
            <>
            <div>
            <div style={{backgroundColor:"black",display:"flex",flexDirection:"column",alignItems:"center",fontSize:"12px"}}>
                <div id="element-to-convert" style={{width:720,backgroundColor:"white",padding:"20px"}}>
          <div style={{position:"relative"}}>
           <div><img src='mylogo.png' alt='WaterMark' style={{width:"300px",position:'absolute',top: "30%", left: "30%",opacity:0.15}}></img></div>
                <div style={{border:"2px solid black",padding:"10px"}}>
                    <div style={{display:"flex",justifyContent:"space-between"}}>
                        <div>सिवनी न्यायालय के अंतर्गत</div>
                        <div style={{marginLeft:"75px"}}>|| श्री गणेशाय नमः ||</div>
                        <div>Email: mountreach.organic@gmail.com</div>
                    </div>
                    <div style={{display:"flex",justifyContent:"space-between"}}>
                        <div>GSTIN NO. -  23AALCM7165N1ZW</div>
                        <div style={{fontWeight:"bold"}}>Mob. 8319633066</div>
                    </div>
                    <div>
                        <div>मंडी लाइसेंस - 4562211-01012-00724</div>
                    </div>
                    <div style={{textAlign:"center",marginTop:"-30px"}}>
                        <div><img src='mylogo.png' alt='Logo' style={{width:"200px"}}></img></div>
                        <div>Reg. Off: Kendnlya Vdhyalya Road Kabir Ward Seoni Dist Seoni (M.P.) 480661</div>
                        <div style={{fontWeight:"bold"}}>All Types of Organic Grains, Maize, Wheat, Rice, Pulses Oil, Cattee feed All Agree Commodities</div>
                    </div>
                    <div style={{display:"flex",justifyContent:"space-between",marginTop:"20px"}}>
                        <div>क्र.{bill.BillNumber}</div>
                        <div>दिनांक - {bill.Date}</div>
                    </div>
                    <div>क्रेता का नाम श्री {bill.Name}</div>
                    <div style={{display:"flex",justifyContent:"space-between"}}>
                        <div> हस्ते.....................................................................................................................</div>
                        <div>ब्रोकर - {bill.Agent}</div> 
                    </div>
                    <div>क्रेता का GSTIN नं. - {bill.GST}</div>
                    <div>
                        <table style={{width:"100%",border:"2px solid black",borderCollapse: "collapse",margin:"10px 0",textAlign:"center"}}>
                            <tr>
                                <th style={{border:"1px solid black",padding:"8px"}}>क्र.</th>
                                <th style={{border:"1px solid black"}}>माल का नाम</th>
                                <th style={{border:"1px solid black"}}>बोरा/बोरी</th>
                                <th style={{border:"1px solid black"}}>भरती</th>
                                <th style={{border:"1px solid black"}}>वजन</th>
                                <th style={{border:"1px solid black"}}>भाव</th>
                                <th style={{border:"1px solid black"}}>माल का कीमत</th>
                            </tr>
                            
                            {/* {bill.map((item, index) => ( */}
                                <tr>
                                  <td style={{border:"1px solid black",padding:"8px"}}>1</td>
                                <td style={{border:"1px solid black"}}>{bill.product}</td>
                                <td style={{border:"1px solid black"}}>{bill.bags}</td>
                                <td style={{border:"1px solid black"}}>{bill.average}</td>
                                <td style={{border:"1px solid black"}}>{bill.weight}</td>
                                <td style={{border:"1px solid black"}}>{bill.rate}</td>
                                <td style={{border:"1px solid black"}}>{bill.amount}/-</td>
                                
                                </tr>
                            {/* ))} */}
                
                                <tr>
                                  <td style={{border:"1px solid black",padding:"8px"}}></td>
                                <td style={{border:"1px solid black"}}></td>
                                <td style={{border:"1px solid black"}}></td>
                                <td style={{border:"1px solid black"}}></td>
                                <td style={{border:"1px solid black"}}></td>
                                <td style={{border:"1px solid black",fontWeight:"bold"}}>कुल राशि -</td>
                                <td style={{border:"1px solid black",fontWeight:"bold"}}>{bill.Total}/-</td>
                                
                                </tr>
                                
                               
                            
                        </table>
                    </div>
                    <div>कुल राशि शब्दों में - {convertToWords(bill.Total)} Only </div>
                    <hr style={{border:" 1px solid black"}}/>
    
                    <div style={{display:"flex",justifyContent:"space-between"}}>
                        <div> कुल भाडा-{bill.freight}</div>
                        <div>एडवास.- {bill.advance}</div>
                        <div>  शेष भाडा.............................................</div>
                    </div>
                    <hr style={{border:" 1px solid black"}}/>
                    <div>नोट - माल तौलने के बाद जोखिम लेने वाले पर रहेगी । रकम एक सप्ताह में न आने से शुरू तिधि से ब्याज प्रति माह 2 रूपये सैकड़ा लगेगा। उपरोक्त बिल में जो माल निर्देशित है, उस माल के गुण की हम खात्री देते है (मूल चूक लेना देना) |</div>
                    <hr style={{border:" 1px solid black"}}/>
                    <div style={{display:"flex",justifyContent:"space-between"}}>
                        <div> ट्रांसपोर्ट का नाम- {bill.transport}</div>
                        <div> मो. नं - {bill.tmobile}</div>
                        <div>वाहन क्रमांक - {bill.VehicleNo}</div>  
                    </div> 
                    <hr style={{border:" 1px solid black"}}/>
                    <div style={{display:"flex",justifyContent:"space-between"}}>
                       <div>ड्राइवर का नाम -  {bill.Dname}</div> 
                       <div>मो. नं० - {bill.Dnumber}</div>
                       <div>लाइसेंस नं - {bill.Lnumber}</div> 
                    </div> 
                
                    <hr style={{border:" 1px solid black"}}/>
                    
                    <div style={{display:"flex",justifyContent:"space-between"}}>
                        <div>उपरोक्त बिल का पेमेन्ट हमारे खाते में ही डालें।</div> 
                        <div><img src='sign.png' style={{width:"100px"}}></img></div>
                    </div>
                    <div style={{display:"flex",justifyContent:"space-between"}}>
                        <div>Central Bank Of India, Branch - Kanjai</div>
                        <div>For - Mountreach</div>
                    </div>
                    <div style={{display:"flex",justifyContent:"space-between"}}>
                        <div>A/c-5469996873</div>
                        <div>Oragnic Artifact Pvt. Ltd.</div>
                    </div>
                    <div>IFSC Code -CBIN0282672</div> 
                      
                    
                    </div>
                </div>
        <div style={{display:"flex",justifyContent:"center",alignItems:"center",margin:"20px"}}>
          <MDBBtn rounded className='mx-2' color='primary' onClick={convertToPdf}>Convert to PDF</MDBBtn>
                  
        </div>
        </div>
            </div>
             
        </div>
    </>
        )
    }else{


  return (
    <div>
      <MyNav/>
      <div style={{
 margin:"20px 10% 0px 10%",
 display:'flex',
 flexDirection:'row',
 flexFlow:'wrap',
 justifyContent:"space-around"
      }}>
        <div>
            <label>From Date</label>
        <input type='date' value={fromdate} onChange={(e)=>setfromdate(e.target.value)} style={{width:200,padding:5,marginLeft:5}}/>
        </div>
        
        
        <div>
            <label>To Date</label>
        <input type='date' value={Todate} onChange={(e)=>setTodate(e.target.value)} style={{width:200,padding:5,marginLeft:5}}/>
        </div>

        <MDBBtn className='me-1' color='success' onClick={exportToExcel}>
        Download Excel File
      </MDBBtn>
      </div>
 <div style={{display:"flex",justifyContent:"center"}}>
<table style={{width:"95%",marginTop:20,textAlign:"center"}}>
               <thead> <tr style={{backgroundColor:"#322869",color:'white',fontSize:12}}>
                    <th style={{padding:5}}>S.no.</th>
                    <th>Bill Number</th>
                    <th>Date</th>
                    <th>Name</th>
                    <th>GST</th>
                    <th>Product</th>
                    <th>Agent</th>
                    <th>Bags</th>
                    <th>rate</th>
                    <th>weight</th>
                    <th>Weight Type</th>
                    <th>Average</th>
                    <th>Amount</th>
                    <th>Mandi Tax</th>
                    {/* <th>Commission</th> */}
                    <th>Other Tax</th>
                    <th>Total</th>
                    <th>Download</th>
                </tr></thead> 
                <tbody>
                {newdata.map((item, index) => (
                <tr key={index} style={{fontSize:12,backgroundColor:color(index)}}>
                      <td style={{padding:5}}>{index + 1}</td>
                      <td>{item.BillNumber}</td>
                      <td>{item.Date}</td>
                      <td>{item.Name}</td>
                      <td>{item.GST}</td>
                      <td>{item.product}</td>
                      <td>{item.Agent}</td>
                      <td>{item.bags}</td>
                      <td>{item.rate}</td>
                      <td>{item.weight}</td>
                      <td>{item.weighttype}</td>
                      <td>{item.average}</td>
                      <td>{item.amount}</td>
                      <td>{item.MandiTax}</td>
                      {/* <td>{item.Commison}</td> */}
                      <td>{item.OtherTax}</td>
                      <td>{item.Total}</td>
                      <td><MDBIcon fas icon='download' onClick={()=>{navi('/downbill/'+item.BillNumber)}} /></td>
  
                </tr>))}
                </tbody>
                </table>

</div>
    </div>
  )
}
}

export default AdminJeet
