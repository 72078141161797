import React, { useEffect, useState } from 'react';
import {app} from "./api.js";
import { GoogleAuthProvider, getAuth, signInWithPopup, onAuthStateChanged, signOut } from "firebase/auth";
// import { getDatabase, ref, update} from "firebase/database";
import Home from './Home.js';
import { MDBBtn,MDBInput } from 'mdb-react-ui-kit';
import { getDatabase, ref, update,push,child,get} from "firebase/database";

import {useNavigate} from 'react-router-dom'


const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getDatabase(app);
const dbRef = ref(getDatabase(app));

const Login = () => {

  const [AUser, setAUser] = useState(false);



  const navi = useNavigate();

  useEffect(() => {


    // if (userid.length>9) {
    //   get(child(dbRef, `password/`+parseInt(userid)+'/')).then((snapshot) => {
    //     if (snapshot.exists()) {
    //       console.log(snapshot.val());
    //     setpass(snapshot.val())
    //     } else {
    //       console.log("No data available");
    //     }
    //   }).catch((error) => {
    //     console.error(error);
    //   });
    // }


    onAuthStateChanged(auth, user =>{
      if(user){
        console.log(user)
        // setAUser(true);

        get(child(dbRef, `auth/`+user.uid)).then((snapshot) => {
          if (snapshot.exists()) {
            const check = snapshot.val()
            if (check.status==="false") {
              navi('/wait')
            } else {
              setAUser(true);
            }
          } else {
            console.log("No data available");
            navi('/')
          }
        }).catch((error) => {
          console.error(error);
          navi('/')
        });

      }else{

      }
    });





    // get(child(dbRef, `users/`+uid)).then((snapshot) => {
    //   if (snapshot.exists()) {
    //     let data = snapshot.val();
    //     setAdd(data.add);
    //     setPincode(data.pincode);
    //     setNumber(data.number);

    //   } else {
    //     console.log("No data available");
    //   }
    // }).catch((error) => {
    //   console.error(error);
    // });



  },[]);

  function login(){

    signInWithPopup(auth, provider)
    .then((result) => {
      console.log(result);


      onAuthStateChanged(auth, user =>{
        if(user){
          console.log(user)
          
  
  
          get(child(dbRef, `auth/`+user.uid)).then((snapshot) => {
            if (snapshot.exists()) {
              const old = snapshot.val()
              if (old.email===user.email) {
                
              } else {
                update(ref(db, 'auth/'+ user.uid), {
                  email: user.email,
                  status: "false",
                  id:user.uid
                });
              }
  
  
            } else {
              update(ref(db, 'auth/'+ user.uid), {
                email: user.email,
                status: "false",
                id:user.uid
              });
            }
          }).catch((error) => {
            // console.error(error);

            update(ref(db, 'auth/'+ user.uid), {
              email: user.email,
              status: "false",
              id:user.uid
            });
          });
  
        }else{
  
        }
      });




    }).catch((error) => {
      console.log(error);
    });

  }

//  useEffect(() => {
//    if (mainpass.length>=4&&mainpass===pass) {
//     setshow(true);
//     console.log("hy")
//    }else{
//     console.log("by")

//     setshow(false)
//    }
//  }, [mainpass])
 

  const logout=()=>{
    signOut(auth).then(() => {
      setAUser(false)
    }).catch((error) => {
      
    });
  }

if (AUser) {
  return (
    <>
    <div style={{
      display:'flex',
      backgroundColor:'white',
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
  }}>
      <div style={{
          textAlign:'start',
          width:'60%'
      }}>
          <h1 style={{
              color:'#0BC89F',
              margin:'13px 0 15px 15px',
              fontSize:22,
              fontWeight:700
          }}>MOUNTREACH</h1>

      </div>
      <div style={{
          textAlign:'end',
          width:'40%'
      }}>
      <i style={
          {
              padding:10,
              backgroundColor:'#EAEAEA',
              borderRadius:20,
              margin:'10px 20px 10px 0'
          }
      } onClick={logout} className="fa-solid fa-right-from-bracket"></i>
      </div>
  </div>
    <Home/>
    </>
  )
} else {
  return (
    <div>

     
    <div style={{display:"flex",height:"100vh",justifyContent:"center",alignItems:"center"}}>

      {/* <div> */}
{/* 
    <MDBInput label='Phone number input' id='typePhone' type='tel' onChange={(e)=>setuserid(e.target.value)}/>
    <br></br>

    {pass!==""&&<MDBInput label='Password input' id='typePassword' type='password' onChange={(e)=>setinpass(e.target.value)}/>}
    <br></br> */}

      <MDBBtn rounded className='mx-2' color='dark' onClick={login}>login</MDBBtn>
              
      {/* </div> */}

    </div>
    </div>
  )
}

}
export default Login
