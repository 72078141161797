import React, { useEffect, useState } from 'react'
import { MDBBtn, MDBIcon, MDBInput } from 'mdb-react-ui-kit';
import {  getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, update,push,child,get} from "firebase/database";
import {app} from "./api.js";
import {useNavigate} from 'react-router-dom'
import FileUp from './FileUp.js';




const db = getDatabase(app);
const dbRef = ref(getDatabase(app));
const auth = getAuth(app);


const Buy = () => {

const [id, setid] = useState(null);


    const navi = useNavigate();
    useEffect(() => {
      onAuthStateChanged(auth, user =>{
        if(user){

          get(child(dbRef, `auth/`+user.uid)).then((snapshot) => {
            if (snapshot.exists()) {
              const check = snapshot.val()
              if (check.status==="false") {
                navi('/wait')
              } else {
                setshowme(true)
              }
            } else {
              console.log("No data available");
              navi('/')
            }
          }).catch((error) => {
            console.error(error);
            navi('/')
          });

        }else{
          navi('/')
        }
      });
    }, [])


    const [pdata, setpdata] = useState([]);
    const [selldata, setselldata] = useState([]);
    const [sdata, setsdata] = useState([]);

  const [AUser, setAUser] = useState("");

  


    const getdata=()=>{
        get(child(dbRef, `product/`)).then((snapshot) => {
            if (snapshot.exists()) {
              console.log(Object.values(snapshot.val()));
            setpdata(Object.values(snapshot.val()))
            } else {
              console.log("No data available");
            }
          }).catch((error) => {
            console.error(error);
          });
    }

    useEffect(() => {

      onAuthStateChanged(auth, user =>{
        if(user){
          setAUser(user.email);
        }else{
  
        }
      });

      getdata()
    }, [])

    const color =(index)=>{
        if ((index+1)% 2===0) {
          return "#D5FFFC"
        } else {
          return "white"
        }
      }

      useEffect(() => {

        if (selldata.weight&&selldata.rate&&selldata.bags) {
          if (selldata.weighttype==="kg") {
            const avrg = parseFloat(selldata.weight)/parseFloat(selldata.bags)
            const am = (parseFloat(selldata.weight)/100)*parseFloat(selldata.rate)
            setselldata({...selldata, average: avrg.toFixed(2),amount: am.toFixed(2)})
    
              
            }else if (selldata.weighttype==="qt") {
            const avrg = (parseFloat(selldata.weight)*100)/parseFloat(selldata.bags)
            const am = parseFloat(selldata.weight)*parseFloat(selldata.rate)
            setselldata({...selldata, average: avrg.toFixed(2),amount: am.toFixed(2)})
    
              
            }else if (selldata.weighttype==="mt") {
            const avrg = (parseFloat(selldata.weight)*1000)/parseFloat(selldata.bags)
            const am = parseFloat(selldata.weight)*10*parseFloat(selldata.rate)
            setselldata({...selldata, average: avrg.toFixed(2),amount: am.toFixed(2)})
    
            }
        }
        
      }, [selldata.weight,selldata.bags,selldata.rate,selldata.weighttype])
      

      const Add=()=>{
        setsdata(prevItems => [...prevItems,selldata]);
        console.log(sdata);
        // addProduct()
    }
    const removeItem = (index) => {
      if (sdata.length > 0) {
        const indexToRemove = index;
        const filteredData = sdata.filter((item, index) => index !== indexToRemove);
        setsdata(filteredData);
      }
    };
    const [Totalamount, setTotalamount] = useState("");
              let total;
              total = 0;
              useEffect(() => {
                for (let i = 0; i < sdata.length; i++){
                    const bal = sdata[i].amount
                    total = total + parseFloat(bal)
                }
                setTotalamount(total);
              }, [sdata])


    const Gbill=()=>{

      get(child(dbRef, `buy/`)).then((snapshot) => {
        if (snapshot.exists()) {
          const num = Object.values(snapshot.val());
          console.log(num.length);
          const c = num.length+1;
          upload(c)
        } else {
          const c = 1
          upload(c)
        }
      }).catch((error) => {
        console.error(error);
      });


      
        
      }

      const upload=(c)=>{

       

      const newPostKey = push(child(ref(db), 'buy/')).key;


        const data = {
          Name: sdata[0].name,
          GST: sdata[0].gst,
          Agent: sdata[0].agent,
          Pushkey: newPostKey,
          Total:Totalamount,
          Date:new Date(),
          Email: AUser,
          Url : id,
          Status: 'pending',
          BillNumber: c,
          product: 
            sdata
          
        };

        update(ref(db, 'buy/'+ newPostKey+'/'), data).then(()=>navi('/'));
        console.log("Done")
      }

      const [showme, setshowme] = useState(false)

      if (showme) {
        return(
          <>

        <div style={{
              display:'flex',
              backgroundColor:'white',
              boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
          }}>
              <div style={{
                  textAlign:'start',
                  width:'60%'
              }}>
                  <h1 style={{
                      color:'#0BC89F',
                      margin:'13px 0 15px 15px',
                      fontSize:22,
                      fontWeight:700
                  }}>MOUNTREACH</h1>
        
              </div>
              <div style={{
                  textAlign:'end',
                  width:'40%'
              }}>
              {/* <i style={
                  {
                      padding:10,
                      backgroundColor:'#EAEAEA',
                      borderRadius:20,
                      margin:'10px 20px 10px 0'
                  }
              }  className="fa-solid fa-right-from-bracket"></i> */}
              </div>
          </div>
            
            <div style={{
                textAlign:'center'
            }}>
        
        {sdata.length<1&&<div style={{margin:'20px 20% 0 20%'}}>
                <MDBInput
                onChange={(e)=>setselldata({...selldata, name: e.target.value})}
        label='Name of Seller'
        type='text'
        width={80}
        style={{width:'80%'}}
        />
        </div>}
        
        {sdata.length<1&&<div style={{margin:'20px 20% 0 20%'}}>
                <MDBInput
                onChange={(e)=>setselldata({...selldata, gst: e.target.value})}
        label='GST No.'
        type='text'
        width={80}
        style={{width:'80%'}}
        />
        </div>}

        {sdata.length<1&&<div style={{margin:'20px 20% 0 20%'}}>
                <MDBInput
                onChange={(e)=>setselldata({...selldata, PAN: e.target.value})}
        label='PAN No.'
        type='text'
        width={80}
        style={{width:'80%'}}
        />
        </div>}

        {sdata.length<1&&<div style={{margin:'20px 20% 0 20%'}}>
                <MDBInput
                onChange={(e)=>setselldata({...selldata, Mobile: e.target.value})}
        label='Mobile No.'
        type='text'
        width={80}
        style={{width:'80%'}}
        />
        </div>}

        {sdata.length<1&&<div style={{margin:'20px 20% 0 20%'}}>
                <MDBInput
                onChange={(e)=>setselldata({...selldata, Address: e.target.value})}
        label='Address'
        type='text'
        width={80}
        style={{width:'80%'}}
        />
        </div>}
        
        <div style={{margin:'20px 20% 0 20%'}}>
                <select style={{width:'100%',padding:'5px'}} onChange={(e)=>setselldata({...selldata, product: e.target.value})}>
                    <option>-Select Product-</option>
                {pdata.map((item, index) => (
          <option value={item.Name} key={index}>{item.Name}</option>
        ))}
                </select>
        </div>
        
        <div style={{margin:'20px 20% 0 20%'}}>
                <select style={{width:'100%',padding:'5px'}} onChange={(e)=>setselldata({...selldata, weighttype: e.target.value})}>
                    <option>--select--</option>
                    <option value='kg'>Kilogram</option>
                    <option value='qt'>Quintal</option>
                    <option value='mt'>Metric Ton</option>
                
                </select>
        </div>
        
        
                <div style={{margin:'20px 20% 0 20%'}}>
                <MDBInput
                onChange={(e)=>setselldata({...selldata, bags: e.target.value})}
        label='Number of Bags'
        type='number'
        width={80}
        style={{width:'80%'}}
        />
        </div>
        
        <div style={{margin:'20px 20% 0 20%'}} >
                <MDBInput
                onChange={(e)=>setselldata({...selldata, weight: e.target.value})}
        label='Weight'
        type='number'
        width={80}
        style={{width:'80%'}}
        />
        </div>
        
        <div style={{margin:'20px 20% 20px 20%'}}>
        <MDBInput
        onChange={(e)=>setselldata({...selldata, rate: e.target.value})}
        label='Rate'
        type='number'
        width={80}
        style={{width:'80%'}}
        />
        </div>
        
        {sdata.length<1&&<div style={{margin:'20px 20% 20px 20%'}}>
        <MDBInput
        onChange={(e)=>setselldata({...selldata, agent: e.target.value})}
        label='Agent'
        type='text'
        width={80}
        style={{width:'80%'}}
        />
        </div>}

        <div style={{margin:'20px 20% 20px 20%'}}>
<MDBInput
value={selldata.amount}
label='Total Amount'
type='number'
width={80}
style={{width:'80%'}}
/>
</div>

        {sdata.length<1&&<div style={{margin:'20px 20% 20px 20%'}}>
        <MDBInput
        onChange={(e)=>setselldata({...selldata, paid: e.target.value})}
        label='Advance'
        type='text'
        width={80}
        style={{width:'80%'}}
        />
        </div>}


        <div style={{margin:'20px 20% 20px 20%'}}>
<MDBInput
value={selldata.amount-selldata.paid}
label='Balance'
type='number'
width={80}
style={{width:'80%'}}
/>
</div>
        

     <FileUp x = {id} setX={setid} title='Receipt'/>
        
        
        
      <MDBBtn onClick={Add} className='me-1' color='success' style={{width:'60%'}}>
        Add
        </MDBBtn>
        {sdata.length>0&&<>
          
          
          <div style={{display:"flex",justifyContent:"center"}}>
        <table style={{width:"95%",marginTop:20}}>
                       <thead> <tr style={{backgroundColor:"#322869",color:'white',fontSize:12}}>
                            <th style={{padding:5}}>S.no.</th>
                            <th>Product Name</th>
                            <th>Number of Bags</th>
                            <th>Average</th>
                            <th>Weight</th>
                            <th>Rate</th>
                            <th>Amount</th>
                            <th>Edit</th>
                        </tr></thead> 
                        <tbody>
                        {sdata.map((item, index) => (
                        <tr key={index} style={{fontSize:12,backgroundColor:color(index)}}>
                              <td style={{padding:5}}>{index + 1}</td>
                              <td>{item.product}</td>
                              <td>{item.bags}</td>
                              <td>{item.average}</td>
                              <td>{item.weight}</td>
                              <td>{item.rate}</td>
                              <td>{item.amount}</td>
                              <td><i onClick={()=>removeItem(index)} className="fa-solid fa-xmark"></i></td>
                              
                        </tr>))}
                        {
                    sdata.length!==0&&  <tr style={{fontSize:12,backgroundColor:'#D9E1FF',fontWeight:'bold',marginBottom:"50px"}}>
                    <td style={{padding:5}}>{'>'}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style={{padding:5}}>Total</td>
                    <td>{Totalamount}</td>
                    <td></td>
                  </tr>
                  }
                        </tbody>
                        </table>
        
        </div>
        <MDBBtn onClick={Gbill} className='me-1' color='success' style={{width:'60%'}}>
        Submit
        </MDBBtn>
        
        </>
        }
        
        
        <br></br>
<br></br>
<button style={{width:200}} type="button" class="btn btn-warning" onClick={()=>{navi('/')}} data-mdb-ripple-init>Back to Home</button>

<br></br>
<br></br>
<br></br>

        
            </div>
            </>
        )
      } else {
        return(
          <div>
            Loading...
          </div>
        )
      }


}

export default Buy
