// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDrhGgECYhTxAk3mDWRWp0AcAHc5EoJy6U",
  authDomain: "mountreach-pos.firebaseapp.com",
  databaseURL: "https://mountreach-pos-default-rtdb.firebaseio.com",
  projectId: "mountreach-pos",
  storageBucket: "mountreach-pos.appspot.com",
  messagingSenderId: "889131879667",
  appId: "1:889131879667:web:c262c323c113853c307b86",
  measurementId: "G-YT6M12PGLE"
};
// Initialize Firebase
export const app = initializeApp(firebaseConfig);
