import React, { useEffect, useState } from 'react'
import { MDBBtn, MDBIcon, MDBInput } from 'mdb-react-ui-kit';
import {  getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, update,push,child,get} from "firebase/database";
import {app} from "./api.js";
import html2pdf from 'html2pdf.js';
import {useNavigate} from 'react-router-dom'
import logo from './mylogo.png'
import Sign from './Sign.png'



const dbRef = ref(getDatabase(app));
const auth = getAuth(app);

export const Download = () => {

  const [AUser, setAUser] = useState([]);
  const [pr, setpr] = useState(false);
  const [bill, setbill] = useState()

  const [showme, setshowme] = useState(false)


  const navi = useNavigate();
  useEffect(() => {
    onAuthStateChanged(auth, user =>{
      if(user){


        get(child(dbRef, `auth/`+user.uid)).then((snapshot) => {
          if (snapshot.exists()) {
            const check = snapshot.val()
            if (check.status==="false") {
              navi('/wait')
          
            } else {
              setshowme(true);
            }
          } else {
            console.log("No data available");
            navi('/')
          }
        }).catch((error) => {
          console.error(error);
          navi('/')
        });


      }else{
        navi('/')
      }
    });
  }, [])

    useEffect(() => {

        onAuthStateChanged(auth, user =>{
          if(user){

            get(child(dbRef, `bills/`)).then((snapshot) => {
                if (snapshot.exists()) {
    
                    const fdata = Object.values(snapshot.val())
                    const Ac = fdata.filter((item)=> item.Status==='Accepted')
                    const mybill = Ac.filter((item)=> item.Email===user.email)
                  setAUser(mybill)
                } else {
                  console.log("No data available");
                }
              }).catch((error) => {
                console.error(error);
              });

          }else{
    
          }
        });
  
      }, [])

      const convertToPdf = () => {
        const element = document.getElementById('element-to-convert'); // Replace 'element-to-convert' with the ID of the HTML element you want to convert
    
        if (!element) {
          console.error('Element not found');
          return;
        }
    
        html2pdf()
          .from(element)
          .save();
      };




      const convertToWords = (num) => {
        const units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
        const teens = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
        const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
    
        if (num === 0) return 'Zero';
    
        let words = '';
    
        if (num >= 10000000 && num <= 999999999) {
          words += convertToWords(Math.floor(num / 10000000)) + ' Crore ';
          num %= 10000000;
        }
    
        if (num >= 100000 && num <= 9999999) {
          words += convertToWords(Math.floor(num / 100000)) + ' Lakh ';
          num %= 100000;
        }
    
        if (num >= 1000 && num <= 99999) {
          words += convertToWords(Math.floor(num / 1000)) + ' Thousand ';
          num %= 1000;
        }
    
        if (num >= 100 && num <= 999) {
          words += units[Math.floor(num / 100)] + ' Hundred ';
          num %= 100;
        }
    
        if (num >= 20 && num <= 99) {
          words += tens[Math.floor(num / 10)] + ' ';
          num %= 10;
        }
    
        if (num >= 10 && num <= 19) {
          words += teens[num - 10];
          num = 0;
        }
    
        if (num >= 1 && num <= 9) {
          words += units[num];
        }
    
        return words.trim();
      };


if (pr) {
    return (
      <>
      {bill&&<div style={{fontWeight:600}}>
        <div style={{backgroundColor:"black",display:"flex",flexDirection:"column",alignItems:"center",fontSize:"12px"}}>
            <div id="element-to-convert" style={{width:800,backgroundColor:"white",padding:"20px"}}>
      <div style={{position:"relative"}}>
       <div><img src={logo} alt='WaterMark' style={{width:"300px",position:'absolute',top: "30%", left: "30%",opacity:0.15}}></img></div>
            <div style={{border:"2px solid black",padding:"10px"}}>
                <div style={{display:"flex",justifyContent:"space-between"}}>
                    <div>सिवनी न्यायालय के अंतर्गत</div>
                    <div style={{marginLeft:"75px"}}>|| श्री गणेशाय नमः ||</div>
                    <div>Email: mountreach.organic@gmail.com</div>
                </div>
                <div style={{display:"flex",justifyContent:"space-between"}}>
                    <div>GSTIN NO. -  23AALCM7165N1ZW</div>
                    <div style={{fontWeight:"bold"}}>Mob. 8319633066</div>
                </div>
                <div>
                    <div>मंडी लाइसेंस - 4562211-01012-00724</div>
                </div>
                <div style={{textAlign:"center",marginTop:"-30px"}}>
                    <div><img src={logo} alt='Logo' style={{width:"200px"}}></img></div>
                    <div>Reg. Off: Kendnlya Vdhyalya Road Kabir Ward Seoni Dist Seoni (M.P.) 480661</div>
                    <div style={{fontWeight:"bold"}}>All Types of Organic Grains, Maize, Wheat, Rice, Pulses Oil, Cattee feed All Agree Commodities</div>
                </div>
                <div style={{display:"flex",justifyContent:"space-between",marginTop:"20px"}}>
                    <div>क्र.{bill.BillNumber}</div>
                    <div>दिनांक - {bill.Date}</div>
                </div>
                <div>क्रेता का नाम श्री {bill.Name}</div>
                <div style={{display:"flex",justifyContent:"space-between"}}>
                    <div> हस्ते.....................................................................................................................</div>
                    <div>ब्रोकर - {bill.Agent}</div> 
                </div>
                <div>क्रेता का GSTIN नं. - {bill.GST}</div>
                <div>
                    <table style={{width:"100%",border:"2px solid black",borderCollapse: "collapse",margin:"10px 0",textAlign:"center"}}>
                        <tr>
                            <th style={{border:"1px solid black",padding:"8px"}}>क्र.</th>
                            <th style={{border:"1px solid black"}}>माल का नाम</th>
                            <th style={{border:"1px solid black"}}>बोरा/बोरी</th>
                            <th style={{border:"1px solid black"}}>भरती</th>
                            <th style={{border:"1px solid black"}}>वजन</th>
                            <th style={{border:"1px solid black"}}>भाव</th>
                            <th style={{border:"1px solid black"}}>माल का कीमत</th>
                        </tr>
                       
                        {bill.product.map((item, index) => (
                            <tr>
                              <td style={{border:"1px solid black",padding:"8px"}}>{index+1}</td>
                            <td style={{border:"1px solid black"}}>{item.product}</td>
                            <td style={{border:"1px solid black"}}>{item.bags}</td>
                            <td style={{border:"1px solid black"}}>{item.average}</td>
                            <td style={{border:"1px solid black"}}>{item.weight}</td>
                            <td style={{border:"1px solid black"}}>{item.rate}</td>
                            <td style={{border:"1px solid black"}}>{item.amount}/-</td>
                            
                            </tr>
                        ))} 
            
                            <tr>
                              <td style={{border:"1px solid black",padding:"8px"}}></td>
                            <td style={{border:"1px solid black"}}></td>
                            <td style={{border:"1px solid black"}}></td>
                            <td style={{border:"1px solid black"}}></td>
                            <td style={{border:"1px solid black"}}></td>
                            <td style={{border:"1px solid black",fontWeight:"bold"}}>कुल राशि -</td>
                            <td style={{border:"1px solid black",fontWeight:"bold"}}>{bill.Total}/-</td>
                            
                            </tr>
                            
                           
                        
                    </table>
                </div>
                <div>कुल राशि शब्दों में - {convertToWords(bill.Total)} Only </div>
                <hr style={{border:" 1px solid black"}}/>

                <div style={{display:"flex",justifyContent:"space-between"}}>
                    <div> कुल भाडा-{bill.product[0].freight}</div>
                    <div>एडवास.- {bill.product[0].advance}</div>
                    <div>  शेष भाडा....{bill.product[0].freight-bill.product[0].advance}....</div>
                </div>
                <hr style={{border:" 1px solid black"}}/>
                <div>नोट - माल तौलने के बाद जोखिम लेने वाले पर रहेगी । रकम एक सप्ताह में न आने से शुरू तिधि से ब्याज प्रति माह 2 रूपये सैकड़ा लगेगा। उपरोक्त बिल में जो माल निर्देशित है, उस माल के गुण की हम खात्री देते है (मूल चूक लेना देना) |</div>
                <hr style={{border:" 1px solid black"}}/>
                <div style={{display:"flex",justifyContent:"space-between"}}>
                    <div> ट्रांसपोर्ट का नाम- {bill.product[0].transport}</div>
                    <div> मो. नं - {bill.product[0].tmobile}</div>
                    <div>वाहन क्रमांक - {bill.product[0].VehicleNo}</div>  
                </div> 
                <hr style={{border:" 1px solid black"}}/>
                <div style={{display:"flex",justifyContent:"space-between"}}>
                   <div>ड्राइवर का नाम -  {bill.product[0].Dname}</div> 
                   <div>मो. नं० - {bill.product[0].Dnumber}</div>
                   <div>लाइसेंस नं - {bill.product[0].Lnumber}</div> 
                </div> 
            
                <hr style={{border:" 1px solid black"}}/>
                
                <div style={{display:"flex",justifyContent:"space-between"}}>
                    <div>उपरोक्त बिल का पेमेन्ट हमारे खाते में ही डालें।</div> 
                    <div><img src={Sign} style={{width:"100px"}}></img></div>
                </div>
                <div style={{display:"flex",justifyContent:"space-between"}}>
                    <div>Central Bank Of India, Branch - Kanjai</div>
                    <div style={{fontWeight:600,color:'blue'}}>Auth. Signatory</div>
                </div>
                <div style={{display:"flex",justifyContent:"space-between"}}>
                    <div>A/c-5469996873</div>
                    <div style={{fontWeight:900,color:'blue'}}>MOUNTREACH ORAGANIC</div>
                </div>
              
                <div style={{display:"flex",justifyContent:"space-between"}}>
                    <div>IFSC Code -CBIN0282672</div>
                    <div style={{fontWeight:900,color:'blue'}}>ARTIFACT PVT. LTD.</div>
                </div>

                  
                 
                </div>
            </div>
    </div>
    <div style={{display:"flex",justifyContent:"center",alignItems:"center",margin:"20px"}}>
      <MDBBtn rounded className='mx-2' color='primary' onClick={convertToPdf}>Convert to PDF</MDBBtn>

      <MDBBtn rounded className='mx-2' color='primary' onClick={()=>setpr(false)}>Back</MDBBtn>
    
              
    </div>
        </div>
         
    </div>}
</>
      )
} else {

if (showme) {
  return (
    <div>
        {AUser.map((item, index) => (
<div style={{display:"flex",justifyContent:"space-evenly", boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',margin:"3%",padding:"5px"}}>
 <div style={{padding:"5px"}}>
      <div>{item.Name}</div>  
      <div>{item.Email}</div>  
      <div>{item.Date}</div>  
 </div>
 <div>
 <MDBBtn rounded color='info gradient' onClick={()=>{setpr(true);setbill(item)}}>View</MDBBtn>
</div>
</div>
))}
    </div>
  )
} else {
  return(
    <div>
      loading...
    </div>
  )
}

    
}

  
}
