
import { getDatabase, ref, update,push,child,get,remove} from "firebase/database";
import {app} from "./api.js";
import React, { useEffect, useState } from 'react'
import { MDBBtn, MDBIcon, MDBInput } from 'mdb-react-ui-kit';
import {useNavigate} from 'react-router-dom'
import {  getAuth, onAuthStateChanged } from "firebase/auth";
import {Link,Outlet} from 'react-router-dom'

const auth = getAuth(app);


const db = getDatabase(app);
const dbRef = ref(getDatabase(app));

const Add = () => {

    const [adddata, setadddata] = useState();
    const [pdata, setpdata] = useState([]);
    const [dt, setdt] = useState("");

    const navi = useNavigate();
    useEffect(() => {
      onAuthStateChanged(auth, user =>{
        if(user){

      //     get(child(dbRef, `auth/`+user.uid)).then((snapshot) => {
      //       if (snapshot.exists()) {
      //         const check = snapshot.val()
      //         if (check.status==="false") {
      //           navi('/wait')
      //         } else {
                
      //         }
      //       } else {
      //         console.log("No data available");
      //         navi('/')
      //       }
      //     }).catch((error) => {
      //       console.error(error);
      //       navi('/')
      //     });

        }else{
          navi('/')
        }
      });




      get(child(dbRef, `product/`)).then((snapshot) => {
        if (snapshot.exists()) {
          console.log(Object.values(snapshot.val()));
        setpdata(Object.values(snapshot.val()))
        } else {
          console.log("No data available");
        }
      }).catch((error) => {
        console.error(error);
      });
    }, [])

    const Addproduct=()=>{

        const newPostKey = push(child(ref(db), 'product/')).key;
                    update(ref(db, 'product/'+ newPostKey+'/'), {
              PushKey: newPostKey,
              Name: adddata.name,
              MandiTax: adddata.mtax,
              OtherTax: adddata.otax,
              Commision: adddata.com
            }).then(()=>navi('/'));
        }
    


  return (
    <>
<div style={{
      display:'flex',
      backgroundColor:'white',
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
  }}>
      <div style={{
          textAlign:'start',
          width:'60%'
      }}>
        
          <h1 style={{
              color:'#0BC89F',
              margin:'13px 0 15px 15px',
              fontSize:22,
              fontWeight:700
          }}>MOUNTREACH</h1>

      </div>
      </div>

    
    <div style={{
        textAlign:'center'
    }}>
        <div style={{margin:'20px 20% 0 20%'}}>
        <MDBInput
        onChange={(e)=>setadddata({...adddata, name: e.target.value})}
label='Product Name'
type='text'
width={80}
style={{width:'80%'}}
/>
</div>

<div style={{margin:'20px 20% 0 20%'}}>
        <MDBInput
         onChange={(e)=>setadddata({...adddata, mtax: e.target.value})}
label='Mandi Tax'
type='number'
width={80}
style={{width:'80%'}}
/>
</div>

<div style={{margin:'20px 20% 0 20%'}} >
        <MDBInput
        onChange={(e)=>setadddata({...adddata, otax: e.target.value})}
label='Other Tax'
type='number'
width={80}
style={{width:'80%'}}
/>
</div>

<div style={{margin:'20px 20% 20px 20%'}}>
<MDBInput
onChange={(e)=>setadddata({...adddata, com: e.target.value})}
label='Commision'
type='number'
width={80}
style={{width:'80%'}}
/>
</div>
<MDBBtn onClick={Addproduct} className='me-1' color='success' style={{width:'60%'}}>
Add Product
</MDBBtn>


<div style={{margin:'20px 20% 20px 20%'}}>
        <select value={dt} style={{width:'100%',padding:'5px'}} onChange={(e)=>setdt(e.target.value)}>
            <option>-Select Product-</option>
        {pdata.map((item, index) => (
  <option value={item.PushKey} key={index}>{item.Name}</option>
))}
        </select>
</div>

<button style={{width:200}} type="button" class="btn btn-danger" onClick={()=>{remove(child(dbRef, `product/`+dt)).then(()=>navi('/'))}} data-mdb-ripple-init>Delete Product</button><br></br><br></br>
<button style={{width:200}} type="button" class="btn btn-warning" onClick={()=>{navi('/')}} data-mdb-ripple-init>Back to Home</button>

    </div>
    </>
  )
}

export default Add
